<template>
    <router-link v-for="item in RouterMap(mapper.default, CoreRouter, authRouteMapper)" :key="item.name" :to="item.href" :class="[linkCSS]" @click="sidebar = false" router-link-active="" >
        <component :is="item.icon" :class="[iconCSS]" aria-hidden="true" />
         {{ big ? null : item.name }} 
    </router-link>
</template>

<script setup>
import { RouterMap } from '@/core/var/router';
import { useAuthStore } from '@/core/store/AuthStore';
import CoreRouter from '@/core/router/router';
import { defineEmits, onMounted, ref, watch } from 'vue';
import { defineProps } from 'vue';

const props = defineProps({
    big: {
        type: Boolean
    }
});


const sidebar = ref(true);
const mapper = require(`/src/customers/${process.env.VUE_APP_NEURAXIS_CHANNEL_ID}/router/map.js`);
const LoadauthRouteMapper = require(`/src/customers/${process.env.VUE_APP_NEURAXIS_CHANNEL_ID}/router/auth.js`);
console.log(LoadauthRouteMapper.default)
const auth = useAuthStore();
const emits = defineEmits('sidebarOpen');
const cssBig = ['text-gray-600 hover:bg-primary-300 hover:text-gray-50 group flex items-center text-base font-medium rounded-md p-[5px]']
const linkCSS = ref(cssBig)

const cssCurrentBig = ['group-hover:text-white font-semibold mr-3 h-6 w-6']
const iconCSS = ref(cssCurrentBig)

watch(sidebar, res => {
    emits('sidebarOpen', res);
})

watch(() => props.big, (val) => {
    getStatus(val)
});

onMounted(() => {
    getStatus(props.big)
})

const getStatus = (val) => {
    if (val == true) {
        linkCSS.value = ['text-primary-600 flex items-center text-base font-medium rounded-md p-[5px] logged'];
        iconCSS.value = [' mr-3 h-6 w-6 hover:text-gray-800']
    } else {
        linkCSS.value = cssBig
        iconCSS.value = cssCurrentBig
    }
}

const authRouteMapper = LoadauthRouteMapper.default;

const default_ = {
    userrole: auth.getUserRole,
    mapping: [{ name: 'Data Hub', auth: 0 }, { name: 'System', auth: 0 }]
}
</script>

<style scoped>
 .router-link-active {
    @apply text-white
}
.logged.router-link-exact-active{
    @apply text-gray-600 bg-white
}  
.logged{
    width:50px;
}
</style>