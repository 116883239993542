<script setup>
import { ref, onMounted, watch } from 'vue'
import axios from "axios"
import { FilterMatchMode } from 'primevue/api'

const products = ref()
const product = ref()
const loader = ref(false)
const activeTabIndex = ref(0)
const filter_products = ref({'global': { value: null, matchMode: FilterMatchMode.CONTAINS } })

const view_product_slider = ref(false)

onMounted(() =>{
    get_products()
})

const get_products = () => {
    loader.value = true
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + '/poc/products')
    .then(response => {
        console.log(response.data)
        products.value = response.data
        loader.value = false
    })
}

const view_product = (e) => {
    console.log('view product', e.data)
    product.value = e.data
    view_product_slider.value = true
}

const open_url = (url) => {
    window.open(url, '_blank')
} 

const swiss_date_time = (date) => {
    return new Date(date).toLocaleDateString('de-CH', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' })
}

</script>

<style lang="scss" scoped>
    @import '@/core/assets/primevue/primeflex.scss';
</style>

<style>
.mb_url {
    color: #144B3C;
    text-decoration: underline;
    cursor: pointer;
    font-weight: bold;
}
.mb_green {
    background-color: #144B3C;
    color: #FFF;
    border-color: #FFF;
}

.mb_green_border {
    border-color: #144B3C;
}

.mb_pink {
    background-color: #E34055;
    color: #FFF;
    border-color: #E34055;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    color: #144B3C;
    border-color: #144B3C;
}

.p-tabview .p-tabview-nav .p-tabview-ink-bar {
    background-color: #144B3C;
}
</style>

<template>
    <ProgressSpinner v-if="loader == true" style="position: fixed; z-index: 999; overflow: show; margin: auto; top: 0; left: 0; bottom: 0; right: 0; width: 50px; height: 50px;" strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" aria-label="Loading..." />
    <div class="flex justify-content-center mt-6">
        <div class="surface-card p-4 shadow-3 border-round" style="width: 1200px" >
            <div class="flex justify-content-between">
                <img src="../assets/images/snappy_onyx_small.png" width="200" />
                <Button class="mt-2" rounded icon="pi pi-question" v-tooltip.left="'Was ist Snappy?'" @click="open_url('https://www.snappy.works')" />
            </div>
            <div class="flex justify-content-between">
                <h1 class="font-bold pl-6 pt-1">Proof of Concepts</h1>
            </div>
            <div class="mb-5">
                <div v-if="!loader">
                    <div class="mt-3">
                        <TabView class="col-12" ref="tabview4" v-model:activeIndex="activeTabIndex">
                            <TabPanel>
                                <template #header>
                                    <i class="pi pi-shopping-cart mr-2"></i>
                                    <span class="mr-3">Produkte</span>
                                </template>
                                <DataTable :value="products" @row-click="view_product" :rowHover="true" :paginator="true" :rows="20" :rowsPerPageOptions="[20,50,100]" :globalFilter="filter_products.global.value" :filters="filter_products" dataKey="id" :emptyMessage="loader ? 'Loading...' : 'No records found'" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="{first} bis {last} von {totalRecords}">
                                    <Column field="product_name" header="Name" sortable>
                                        <template #body="slotProps">
                                            <span class="font-bold">{{ slotProps.data.product_name }}</span>
                                        </template>
                                    </Column>
                                    <Column field="product_gallery" header="Bilder" sortable>
                                        <template #body="slotProps">
                                            <span>{{ String(slotProps.data.product_gallery.length) }}</span>
                                        </template>
                                    </Column>
                                    <Column field="product_videos" header="Videos" sortable>
                                        <template #body="slotProps">
                                            <span>{{ String(slotProps.data.product_videos.length) }}</span>
                                        </template>
                                    </Column>
                                    <Column field="product_description" header="Beschreibung">
                                        <template #body="slotProps">
                                            <Avatar v-if="slotProps.data.product_description" icon="pi pi-check" />
                                        </template>
                                    </Column>
                                    <Column field="product_variants" header="Varianten" sortable>
                                        <template #body="slotProps">
                                            <span>{{ String(slotProps.data.product_variants.length) }}</span>
                                        </template>
                                    </Column>
                                </DataTable>
                            </TabPanel>
                        </TabView>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!------------------------------------------------------------------------------------->
    <!------------------------------------------------------------------------------------->
    <!-------------------------------- Sidebar: Sources ----------------------------------->
    <!------------------------------------------------------------------------------------->
    <!------------------------------------------------------------------------------------->
    <Sidebar header="Produkt" v-model:visible="view_product_slider" position="right" style="width: 80%">
        <Accordion expandIcon="pi pi-plus" collapseIcon="pi pi-minus" :multiple="true" class="mt-3">
            <AccordionTab v-for="(product_details, key) in product" :key="key" >
                <template #header>
                    <span class="flex align-items-center gap-2 w-full">
                        <Avatar icon="pi pi-file" shape="circle" />
                        <span class="font-bold white-space-nowrap mr-2">{{ key }}</span>
                        <Badge v-if="product[key]" :value="String(product[key].length)" class="mr-2" />
                    </span>
                </template>
                <span v-if="key == 'product_name'" class="font-bold">{{product_details}}</span>

                <p v-if="key == 'product_description'" class="m-0" style="white-space: pre-wrap;" v-html="product[key]"></p>
                
                <span class="text-primary font-bold cursor-pointer" v-if="key == 'product_url'" @click="open_url(product_details)">{{product_details}}</span>

                <div v-if="key == 'product_description_de' || key == 'product_specifications'">
                    <div v-html="product_details"></div>
                </div>
                <div v-if="key == 'product_variants'">
                    <div class="flex flex-wrap gap-2">
                        <div v-for="(variant, index) in product[key]" :key="index" class="flex flex-column gap-2 rounded bg-blue-100 p-3">
                            <div class="font-bold">{{ variant["sku"] }}</div>
                            <div>{{ variant["price"] }}</div>
                        </div>
                    </div>
                </div>
                <div v-if="key == 'product_gallery'">
                    <div class="flex flex-wrap gap-2">
                        <div v-for="(image, index) in product[key]" :key="index" class="flex flex-column gap-1">
                            <Image preview :src="image" alt="product image" width="150" />
                        </div>
                    </div>
                </div>
                <div v-if="key == 'product_videos'">
                    <div v-for="(video, index) in product[key]" :key="index" class="flex flex-wrap gap-2">
                        <iframe width="560" height="315" :src="video" frameborder="0" allowfullscreen></iframe>
                    </div>
                </div>

            </AccordionTab>
        </Accordion>
    </Sidebar>
</template>